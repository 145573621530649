import Header from "./Header";
import Footer from "./Footer";
import { Box } from "@mui/material";
import pp from "../images/hk.jpg";
import "../App.css";

const Hakkımda = () => {
  const isMobile = window.innerWidth < 500;

  return (
    <div>
      <Header />
      <div className="margin-top-40">
        <div className="centered-flex personal-info-name">Diyetisyen Hilal KILIÇ</div>
        <div className="centered-flex">
          <Box
            component="img"
            sx={{
              height: isMobile ? 250 : 400,
              borderRadius: "100%",
            }}
            alt=""
            src={pp}
            style={{ cursor: "pointer" }}
          />
        </div>

        <div className="body-personal-info text-center centered-flex">
          <div className="personal-info-txt width-50">
          Herkese Merhaba 👋 <br/> Ben diyetisyen Hilal Kılıç. 4 yıllık lisans eğitimimi Hacettepe Üniversite'sinde başarıyla tamamladım. Eğitimim boyunca Hacettepe erişkin - çocuk hastaneleri, Hacettepe Üniversitesi mutfağı, Bilkent Şehir Hastanesi, Gülhane Eğitim Araştırma Hastanesi ve özel bir klinikte stajlarımı yaparak kendimi mesleğime hazırladım. Şu anda da aktif olarak online  beslenme ve diyet danışmanlığı hizmeti vermekteyim. Sizlere beslenme konusunda yol göstermek, sağlıklı beslenme alışkanlıkları kazandırmak ve hedefinize ulaşma konusunda destek olmak için buradayım. Keyifli bir diyet süreci geçirmek için siz de benimle iletişime geçebilirsiniz!
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Hakkımda;
