
const Footer = () => {
    return (
        <div className="footer top-border" >
            <div>
                Telif Hakkı © - Tüm Hakları Saklıdır.
            </div>
            <div>
                Powered By Inteex
            </div>
        </div>
    )

}

export default Footer;