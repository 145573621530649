import MenuIcon from "@mui/icons-material/Menu";
import { Box, Link, Button, IconButton, Modal } from "@mui/material";
import { useState } from "react";
import logo from "../images/removed-bg-logo.png";
import { useNavigate } from "react-router";

const Header = () => {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const isMobile = window.innerWidth < 500;

  const navigate = useNavigate();

  const handleHomePageNavigate = () => {
    navigate("/");
  };

  const handleAboutNavigate = () => {
    navigate("/hakkımda");
  };

  const handleOnlineDietNavigate = () => {
    navigate("/online-diyet");
  };

  const handleContactNavigate = () => {
    navigate("/iletisim");
  };

  const handleClose = () => {
    setNavBarOpen(false);
  };

  return (
    <div className="header">
      <div className="centered-flex align-center">
        <Link href="https://diyetisyenhilalkilic.com">
          <Box
            component="img"
            sx={{
              height: isMobile ? 150 : 200,
              width: isMobile ? 150 : 200,
            }}
            alt=""
            src={logo}
            style={{ cursor: "pointer" }}
          />
        </Link>
      </div>

      {isMobile ? (
        <div>
          {navBarOpen ? (
            <Modal
              open={navBarOpen}
              onClose={handleClose}
              className="modal"
            >
              <div>
                <ul className="responsive-app-bar-ul">
                  <li>
                    <Button
                      className="responsive-app-bar-menu-item"
                      onClick={handleHomePageNavigate}
                    >
                      Ana Sayfa
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="responsive-app-bar-menu-item"
                      onClick={handleAboutNavigate}
                    >
                      Hakkımda
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="responsive-app-bar-menu-item"
                      onClick={handleOnlineDietNavigate}
                    >
                      Online Diyet
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="responsive-app-bar-menu-item"
                      onClick={handleContactNavigate}
                    >
                      İletişim
                    </Button>
                  </li>
                </ul>
              </div>
            </Modal>
          ) : (
            <></>
          )}

          <IconButton
            onClick={() => setNavBarOpen(!navBarOpen)}
          >
            <MenuIcon />
          </IconButton>
        </div>
      ) : (
        <div className="app-bar centered-flex">
          <Button className="app-bar-button" onClick={handleHomePageNavigate}>
            Ana Sayfa
          </Button>
          <Button className="app-bar-button" onClick={handleAboutNavigate}>
            Hakkımda
          </Button>
          <Button className="app-bar-button" onClick={handleOnlineDietNavigate}>
            Online Diyet
          </Button>
          <Button className="app-bar-button" onClick={handleContactNavigate}>
            İletişim
          </Button>
        </div>
      )}
    </div>
  );
};

export default Header;
