import Header from "./Header";
import Footer from "./Footer";
import { Box } from "@mui/material";
import image from "../images/online-diyet-img2.jpg";
import star from "../images/star-icon.png";

const OnlineDiyet = () => {
  const isMobile = window.innerWidth < 500;

  return (
    <div>
      <Header />
      <div className="margin-top-40 centered-flex bold-header">
        Online Diyet
      </div>
      <div className="centered-flex margin-top-40">
        <Box
          component="img"
          sx={{
            width: isMobile ? "100vw" : 600,
          }}
          alt=""
          src={image}
          style={{ cursor: "pointer" }}
        />
      </div>

      <div className="centered-flex margin-top-40 font-1">
        Online diyetin nasıl gerçekleşeceğini mi merak ediyorsunuz? İşte
        birlikte atacağımız adımlar şu şekilde:
      </div>

      <div className="centered-flex margin-top-40">
        <div className="centered-flex width-20 card-2 gap-30 bej-bg-1 relative">
          <div className="tick-pos">
            <Box
              component="img"
              sx={{
                height: isMobile ? 75 : 100,
                borderRadius: "100%",
              }}
              alt=""
              src={star}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="number-2">1</div>
          <div className="personal-info-txt">
            İlk olarak ücretsiz bir ön görüşme ile birbirimizi tanıyoruz. Sizin
            istekleriniz, hedefleriniz ve benden beklentileriniz hakkında bilgi
            alıyorum. Süreci nasıl planlayacağımızı konuşuyoruz.
          </div>
        </div>
        <div className="centered-flex width-20 card-2 gap-30 bej-bg-2 relative">
        <div className="tick-pos">
            <Box
              component="img"
              sx={{
                height: isMobile ? 75 : 100,
                borderRadius: "100%",
              }}
              alt=""
              src={star}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="number-2">2</div>
          <div className="personal-info-txt">
            Sonrasında belirlediğimiz tarihte ilk görüşmemizi
            gerçekleştiriyoruz. Görüşmeler Whatsaap üzerinden görüntülü veya
            sesli bir şekilde gerçekleşiyor. Size en uygun beslenme listesini
            oluşturduktan sonra yine whatsaap üzerinden size gönderiyorum.
            Ölçümlerinizi ise evde kendiniz mezura ve tartı yardımıyla
            alıyorsunuz.
          </div>
        </div>
        <div className="centered-flex width-20 card-2 gap-30 bej-bg-3 relative">
        <div className="tick-pos">
            <Box
              component="img"
              sx={{
                height: isMobile ? 75 : 100,
                borderRadius: "100%",
              }}
              alt=""
              src={star}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="number-2">3</div>
          <div className="personal-info-txt">
            Haftalık olarak kontrol görüşmeleri yapıyoruz. Geçen haftanın
            değerlendirmesini yapıyoruz ,mezura ve tartı ölçüm sonuçlarınızı
            benimle paylaşmanızı istiyorum. Sonuçlara ve sizin ihtiyaçlarınıza
            göre listeyi güncelliyorum ve WhatsApp üzerinden size iletiyorum.
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OnlineDiyet;
