import Header from "./Header";
import Footer from "./Footer";
import { Button, TextField, Snackbar, SnackbarContent } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useState } from "react";

const Iletisim = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [successSnackbar, openSuccessSnackbar] = useState(false);
  const [failureSnackbar, openFailureSnackbar] = useState(false);

  const handleSendRequest = () => {
    try {
      const params = {
        name: name,
        email: email,
        message: message,
      };

      const url =
        "https://script.google.com/macros/s/AKfycbw9p0PhgvDFR1QDJsFIRgG8UicMMfe0X2SQZNeeVJjaHjBN0X8o0L9UaFgHMfnvT5Ml/exec";
      fetch(url, { method: "POST", body: JSON.stringify(params) })
        .then((res) => {
          return res.text();
        })
        .then((res) => {
            res = JSON.parse(res)
          if (res["status"] === "success") {
            openSuccessSnackbar(true);
          } else {
            openFailureSnackbar(true);
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        });
    } catch (e) {
      openFailureSnackbar(true);
    }
  };

  const handleCloseSuccessSnackbar = () => {
    openSuccessSnackbar(false);
  };

  const handleCloseFailureSnackbar = () => {
    openFailureSnackbar(false);
  };

  return (
    <div>
      {successSnackbar ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={successSnackbar}
          onClose={handleCloseSuccessSnackbar}
          autoHideDuration={4000}
        >
          <SnackbarContent
            className="success-snackbar"
            message={<span>Mesajınız başarıyla kaydedildi!</span>}
          />
        </Snackbar>
      ) : (
        <></>
      )}
      {failureSnackbar ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={failureSnackbar}
          onClose={handleCloseFailureSnackbar}
          autoHideDuration={4000}
        >
          <SnackbarContent
            className="failure-snackbar"
            message={<span>Mesajınız kaydedilirken hata oluştu!</span>}
          />
        </Snackbar>
      ) : (
        <></>
      )}
      <Header />
      <div className="margin-top-40 centered-flex bold-header">İletişim</div>
      <div className="centered-flex margin-top-40 gap-200">
        <div className="centered-flex-column">
          <div className="font-1 font-weight-600">
            Danışmanlık için bize ulaşın!
          </div>
          <br />
          <TextField
            required
            label="İsim"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            className="width-100"
          />
          <br />
          <TextField
            required
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            className="width-100"
          />
          <br />
          <TextField
            required
            label="Mesajınız"
            value={message}
            multiline
            rows={4}
            onChange={(e) => setMessage(e.currentTarget.value)}
            className="width-100"
          />
          <br />
          <Button
            className="personal-info-button"
            onClick={handleSendRequest}
            disabled={
              name.length === 0 || !email.includes("@") || message.length === 0
            }
          >
            Submit
          </Button>
        </div>
        <div>
          <div className="font-1 font-weight-600">İletişim Bilgileri</div>
          <div className="margin-top-40">
            <div className="centered-flex align-center row-flex">
              <EmailIcon />{" "}
              <span className="margin-left-10">
                <a className="no-underline-a" href="mailto:iletisim@diyetisyenhilalkilic.com" target="_blank" rel="noreferrer">iletisim@diyetisyenhilalkilic.com</a>
              </span>
            </div>
            <br />
            <div className="left-flex align-center">
              <InstagramIcon />{" "}
              <a className="no-underline-a" href="https://www.instagram.com/dyt.hilalklc/" target="_blank" rel="noreferrer"><span className="margin-left-10">dyt.hilalklc</span></a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Iletisim;
