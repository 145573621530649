import image1 from "../images/photo1.jpg";
import image2 from "../images/online-diyet-img.jpg";
import tick from "../images/tick.png";
import pp from "../images/hk.jpg";
import "../App.css";
import { Box, Button } from "@mui/material";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import { useNavigate } from "react-router";

const Anasayfa = () => {
  const isMobile = window.innerWidth < 500;

  const navigate = useNavigate();

  const handleAboutNavigate = () => {
    navigate("/hakkımda");
  };

  return (
    <div>
      <Header />
      <div className="body">
        <div className="body-image-container centered-flex">
          <Box
            component="img"
            sx={{
              width: isMobile ? "100vw" : 600,
            }}
            alt=""
            src={image1}
            style={{ cursor: "pointer" }}
          />

          <div className="text-container centered-flex">
            <div className="font-2 mb-20 font-weight-300">Merhaba!</div>
            <div className="body-text font-1 font-weight-200">
              Sağlığımızı ve hayat kalitemizi etkileyen faktörlerin başında
              beslenme alışkanlıklarımız gelir. Günümüzde pek çok hastalığın
              kökeninde yanlış beslenme alışkanlıklarının olduğu görülmektedir.
              Bedenimize yapabileceğimiz en güzel iyilik kendi ihtiyaçlarımıza
              göre doğru beslenmeyi öğrenip bunu sürdürülebilir hale
              getirmektir. Gelin birlikte sağlıklı bir yaşama adım atalım!
            </div>
          </div>
        </div>

        <div className="margin-top-40 centered-flex-column">
          <div>
            <Box
              component="img"
              sx={{
                height: isMobile ? 250 : 400,
                borderRadius: "100%"
              }}
              alt=""
              src={pp}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className="body-personal-info text-center">
            <div className="personal-info-name">Diyetisyen Hilal KILIÇ</div>
            <div className="personal-info-txt ">
              Merhaba, ben Diyetisyen Hilal KILIÇ
            </div>
            <Button
              className="personal-info-button"
              onClick={handleAboutNavigate}
            >
              Daha Fazla
            </Button>
          </div>
        </div>

        <div className="margin-top-40 centered-flex">
          <div className="divider">Online Diyet</div>
        </div>

        <div className="margin-top-40 gap-30 centered-flex">
          <Box
            component="img"
            sx={{
              height: 400,
            }}
            alt=""
            src={image2}
            style={{ cursor: "pointer" }}
          />

          <div className="text-container centered-flex">
            <div className="body-text font-2 font-weight-200 ">
              Online diyet ile nerede olursanız olun bir tuşla diyetisyeniniz
              hemen yanınızda!
            </div>
          </div>
        </div>

        <div className="margin-top-40 centered-flex text-center">
          <div className="card centered-flex-column relative">
            <div className="tick-pos">
              <Box
                component="img"
                sx={{
                  height: isMobile ? 75 : 100,
                  borderRadius: "100%",
                }}
                alt=""
                src={tick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="number centered-flex-column">1</div>
            Düzenli olarak egzersiz yapın.
          </div>
          <div className="card centered-flex-column relative">
          <div className="tick-pos">
              <Box
                component="img"
                sx={{
                  height: isMobile ? 75 : 100,
                  borderRadius: "100%",
                }}
                alt=""
                src={tick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="number centered-flex-column">2</div>
            Öğünlerinizde her besin grubuna yer verin.
          </div>
          <div className="card centered-flex-column relative">
          <div className="tick-pos">
              <Box
                component="img"
                sx={{
                  height: isMobile ? 75 : 100,
                  borderRadius: "100%",
                }}
                alt=""
                src={tick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="number centered-flex-column">3</div>Bol bol su
            tüketin.
          </div>
          <div className="card centered-flex-column relative">
          <div className="tick-pos">
              <Box
                component="img"
                sx={{
                  height: isMobile ? 75 : 100,
                  borderRadius: "100%",
                }}
                alt=""
                src={tick}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="number centered-flex-column">4</div>
            Sağlıklı ve sürdürülebilir beslenme planıyla hedefinize ulaşın.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Anasayfa;
