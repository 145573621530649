import React from "react";
import "./index.css";
import Anasayfa from "./pages/Anasayfa";
import Hakkımda from "./pages/Hakkımda";
import OnlineDiyet from "./pages/Online-diyet";
import Iletisim from "./pages/İletişim";

import { Routes, Route } from "react-router-dom";
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Anasayfa />} />
      <Route path="/hakkımda" element={<Hakkımda />} />
      <Route path="/online-diyet" element={<OnlineDiyet />} />
      <Route path="/iletisim" element={<Iletisim />} />
    </Routes>
  );
};

export default App;